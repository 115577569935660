// projectSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Project {
  id: number;
  title: string;
  clientId: string;
}

interface ProjectState {
  currentProject: Project | null;
  projects: Project[];
}

const initialState: ProjectState = {
  currentProject: null,
  projects: [],
};

// Função para carregar o projeto do localStorage
const loadCurrentProjectFromLocalStorage = (): Project | null => {
  try {
    const serializedProject = localStorage.getItem('currentProject');
    if (serializedProject === null) {
      return null;
    }
    return JSON.parse(serializedProject);
  } catch (err) {
    console.error('Could not load current project', err);
    return null;
  }
};

// Função para salvar o projeto no localStorage
const saveCurrentProjectToLocalStorage = (project: Project | null) => {
  try {
    const serializedProject = JSON.stringify(project);
    localStorage.setItem('currentProject', serializedProject);
  } catch (err) {
    console.error('Could not save current project', err);
  }
};

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    ...initialState,
    currentProject: loadCurrentProjectFromLocalStorage(),
  },
  reducers: {
    setProject(state, action: PayloadAction<Project>) {
      state.currentProject = action.payload;
      saveCurrentProjectToLocalStorage(action.payload);
    },
    setProjects(state, action: PayloadAction<Project[]>) {
      state.projects = action.payload;
    },
  },
});

export const { setProject, setProjects } = projectSlice.actions;
export default projectSlice.reducer;
