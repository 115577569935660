// src/components/Prompt.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Prompt: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <div className="w-64 bg-gray-100">
      <div className="p-4">
        {user && (
          <div className="text-xl font-bold mb-4">
            <i className="bi bi-person-circle mr-2"></i> {user.name}
          </div>
        )}
        <hr className="border-t-2 border-gray-300 w-full p-1" />
        <h3 className="flex items-center font-bold text-lg mb-2">
          <i className="bi bi-lightbulb-fill mr-2"></i> Prompts
        </h3>
        <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-4">
          <i className="bi bi-plus mr-2"></i>Criar um Prompt
        </button>
        <ul>
          {[
            'Ideias Inovadoras',
            'Exploração de Conceitos',
            'Desafios Criativos',
            'Soluções Inteligentes',
            'Perguntas Provocativas',
            'Histórias Engajantes',
            'Cenários Futuristas',
            'Brainstorming Rápido',
            'Desenvolvimento de Personagens',
            'Questões Filosóficas'
          ].map((prompt, index) => (
            <li key={index} className="p-2 hover:bg-gray-200 cursor-pointer">{prompt}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Prompt;
