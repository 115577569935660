import { getToken } from './authService';

const { REACT_APP_API_ENDPOINT } = process.env;

export interface AIRequestPayload {
  aiProvider: string;
  model: string;
  stream: boolean;
  user: string;
  system: string;
  rag: string;
  useHistory: boolean;
  chatHistory: string;
  sessionId: number|null;
  personaId: number|null;
}

export interface AIResponse {
  aiProvider: string;
  model: string;
  content: string;
  isSystem: boolean;
  isRag: boolean;
  isPersona: boolean;
}

export async function sendAIRequest(payload: AIRequestPayload): Promise<AIResponse> {
  const token = getToken();

  const url = `${REACT_APP_API_ENDPOINT}/airequest`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return await response.json();
}


export function parsePrompt(prompt: string): [string, string, string, string] {
  let user = '';
  let system = '';
  let rag = '';
  let history = '';

  prompt = prompt.replace(/\\n/g, '\n');

  // Verifica se o prompt contém marcações "##"
  if (!prompt.includes("#user") &&
    !prompt.includes("#system") &&
    !prompt.includes("#rag") &&
    !prompt.includes("#history")) {
    user = prompt.trim();
    return [user, system, rag, history];
  }

  const lines = prompt.split('\n');
  let currentSection = '';

  lines.forEach(line => {
    line = line.trim();

    if (line.startsWith("#user")) {
      currentSection = "user";
    } else if (line.startsWith("#system")) {
      currentSection = "system";
    } else if (line.startsWith("#rag")) {
      currentSection = "rag";
    } else if (line.startsWith("#history")) {
      currentSection = "history";
    } else {
      switch (currentSection) {
        case "user":
          user += line + "\n";
          break;
        case "system":
          system += line + "\n";
          break;
        case "rag":
          rag += line + "\n";
          break;
        case "history":
          history += line + "\n";
          break;
      }
    }
  });

  user = user.trim();
  system = system.trim();
  rag = rag.trim();
  history = history.trim();

  return [user, system, rag, history];
};