import React from "react";
import ChatHeader from "./ChatHeader";
import ChatInput from "./ChatInput";
import Messages from "./Messages";

const Chat: React.FC = () => {
    return (
        <div className="flex-1 flex flex-col bg-white">
            <ChatHeader />
            <div className="flex-1 overflow-y-auto p-4">
                <Messages />
            </div>
            <ChatInput />
        </div>
    );
}

export default Chat;
