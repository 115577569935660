import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { setResponse, addMessage } from '../../store/slices/aiSlice';
import { AIRequestPayload, sendAIRequest } from '../../service/aiService';
import { v4 as uuidv4 } from 'uuid';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const ChatInput: React.FC = () => {
  const [userMessage, setUserMessage] = useState('');
  const [systemMessage, setSystemMessage] = useState('');
  const [ragMessage, setRagMessage] = useState('');
  const aiProvider = useSelector((state: RootState) => state.ai.aiProvider);
  const model = useSelector((state: RootState) => state.ai.model);
  const useHistory = useSelector((state: RootState) => state.ai.useHistory);
  const personaId = useSelector((state: RootState) => state.ai.selectedPersona);
  const currentSession = useSelector((state: RootState) => state.session.currentSession);
  const [error, setError] = useState<string | null>(null);

  const dispatch = useDispatch();


  const handleSendMessage = async () => {
    const user = userMessage;
    const system = systemMessage;
    const rag = ragMessage;

    setUserMessage('');
    setSystemMessage('');
    setRagMessage('');
    setError(null);

    const userMessageObj = {
      id: uuidv4(),
      content: user,
      aiMessage: false,
      aiProvider: aiProvider,
      model: model,
      isSystem: personaId > 0 || systemMessage.trim().length > 0,
      isRag: ragMessage.trim().length > 0,
      isPersona: personaId > 0,
    };

    dispatch(addMessage(userMessageObj)); // Add user's message to the conversation

    try {
      const payload: AIRequestPayload = {
        aiProvider: aiProvider,
        model: model,
        stream: false,
        user: user,
        system: system,
        rag: rag,
        useHistory: useHistory,
        chatHistory: '',
        sessionId: currentSession?.id || null,
        personaId: personaId,
      };

      const response = await sendAIRequest(payload);
      dispatch(setResponse(response.content)); // Gravar resposta no Redux

      console.log('Response >>>>>>>>>>>>>> :', response);

      const aiMessage = {
        id: uuidv4(),
        content: response.content,
        aiMessage: true,
        aiProvider: response.aiProvider,
        model: response.model,
        isSystem: response.isSystem,
        isRag: response.isRag,
        isPersona: response.isPersona,
      };
      dispatch(addMessage(aiMessage)); // Add AI's message to the conversation
      setError(null);
    } catch (error) {
      console.error('Error:', error);
      setError(`Ocorreu um erro ao enviar a mensagem. ${error}`);
    }
  };

  return (
    <div className="p-4 bg-gray-200 flex items-start">
      <div className="flex-1">
        {error && <div className="mt-2 text-red-500">{error}</div>}
        <Tabs>
          <TabList>
            <Tab>User</Tab>
            <Tab>System</Tab>
            <Tab>Rag</Tab>
          </TabList>

          <TabPanel>
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              rows={4}
              placeholder="Digite a mensagem do usuário..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            ></textarea>
          </TabPanel>
          <TabPanel>
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              rows={4}
              placeholder="Digite a mensagem do sistema..."
              value={systemMessage}
              onChange={(e) => setSystemMessage(e.target.value)}
            ></textarea>
          </TabPanel>
          <TabPanel>
            <textarea
              className="w-full p-2 border border-gray-300 rounded"
              rows={4}
              placeholder="Digite a mensagem RAG..."
              value={ragMessage}
              onChange={(e) => setRagMessage(e.target.value)}
            ></textarea>
          </TabPanel>
        </Tabs>
      </div>
      <div className="ml-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          onClick={handleSendMessage}
          disabled={!userMessage.trim()}
        >
          <i className="bi bi-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default ChatInput;
