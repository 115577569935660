import { configureStore } from '@reduxjs/toolkit';
import aiReducer from './slices/aiSlice';
import authReducer from './slices/authSlice';
import projectReducer from './slices/projectSlice';
import sessionReducer from './slices/sessionSlice';

const store = configureStore({
  reducer: {
    ai: aiReducer,
    auth: authReducer,
    project: projectReducer,
    session: sessionReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
