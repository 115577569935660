// sessionSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Session {
  id: number;
  title: string;
}

interface SessionState {
  currentSession: Session | null;
  sessions: Session[];
}

const initialState: SessionState = {
  currentSession: null,
  sessions: [],
};

// Função para carregar a sessão do localStorage
const loadCurrentSessionFromLocalStorage = (): Session | null => {
  try {
    const serializedSession = localStorage.getItem('currentSession');
    if (serializedSession === null) {
      return null;
    }
    return JSON.parse(serializedSession);
  } catch (err) {
    console.error('Could not load current session', err);
    return null;
  }
};

// Função para salvar a sessão no localStorage
const saveCurrentSessionToLocalStorage = (session: Session | null) => {
  try {
    const serializedSession = JSON.stringify(session);
    localStorage.setItem('currentSession', serializedSession);
  } catch (err) {
    console.error('Could not save current session', err);
  }
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    ...initialState,
    currentSession: loadCurrentSessionFromLocalStorage(),
  },
  reducers: {
    setSession(state, action: PayloadAction<Session>) {
      state.currentSession = action.payload;
      saveCurrentSessionToLocalStorage(action.payload);
    },
    setSessions(state, action: PayloadAction<Session[]>) {
      state.sessions = action.payload;
    },
  },
});

export const { setSession, setSessions } = sessionSlice.actions;
export default sessionSlice.reducer;
