import React from 'react';
import Sidebar from '../components/Sidebar/Sidebar';
import Chat from '../components/Chat/Chat';
import Prompt from '../components/Prompts/Prompt';

const Dashboard: React.FC = () => {

    return (
        <div className="flex h-screen">
          <Sidebar/>
          <Chat/>
          <Prompt/>
        </div>
  );
};

export default Dashboard;

