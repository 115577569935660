import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getToken } from '../service/authService';
import { setProject } from '../store/slices/projectSlice';

const { REACT_APP_API_ENDPOINT } = process.env;

const CreateProject: React.FC = () => {
  const [projectName, setProjectName] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateProject = async () => {
    const token = getToken();
    const url = `${REACT_APP_API_ENDPOINT}/chatbot/projects`;
    
    const payload = {
      title: projectName
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create project');
      }

      const data = await response.json();
      console.log('Project Created:', data);

      // Armazenar os dados do projeto no Redux
      dispatch(setProject(data));

      // Redirecionar para a rota dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Create Project</h1>
      <input
        type="text"
        className="border p-2 mb-4 w-full"
        placeholder="Project Name"
        value={projectName}
        onChange={(e) => setProjectName(e.target.value)}
      />
      <button
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        onClick={handleCreateProject}
      >
        Create Project
      </button>
    </div>
  );
};

export default CreateProject;
