import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

interface MessageProps {
    content: string;
    aiMessage: boolean;
    aiProvider?: string;
    model?: string;
    animate?: boolean;
}

const Message: React.FC<MessageProps> = ({ content, aiMessage, aiProvider, model }) => {
    return aiMessage ? (
        <div className="flex flex-col mb-4">
            <div className="flex items-center">
          <span className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center mr-3">
            🤖
          </span>
                <div className="bg-gray-100 p-3 rounded flex-1 max-w-6xl overflow-auto">
                    <Markdown
                        remarkPlugins={[remarkGfm]}
                        rehypePlugins={[rehypeRaw]}
                        components={{
                            code({ node, inline, className, children, ...props }: any) {
                                const match = /language-(\w+)/.exec(className || '');

                                return !inline && match ? (
                                    <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
                                        {String(children).replace(/\n$/, '')}
                                    </SyntaxHighlighter>
                                ) : (
                                    <code className={className} {...props}>
                                        {children}
                                    </code>
                                );
                            },
                        }}
                    >
                        {content}
                    </Markdown>
                    <div className="mt-2 flex justify-between items-center">
                        <div>
                            <button className="hover:bg-blue-500 text-blue-500 hover:text-white font-bold py-1 px-2 rounded text-xl">
                                <i className="bi bi-file-earmark-plus"></i>
                            </button>
                            <button className="hover:bg-blue-500 text-blue-500 hover:text-white font-bold py-1 px-2 rounded text-xl">
                                <i className="bi bi-envelope-fill"></i>
                            </button>
                            <button className="hover:bg-blue-500 text-blue-500 hover:text-white font-bold py-1 px-2 rounded text-xl">
                                <i className="bi bi-share-fill"></i>
                            </button>
                            <button className="hover:bg-blue-500 text-blue-500 hover:text-white font-bold py-1 px-2 rounded text-xl">
                                <i className="bi bi-download"></i>
                            </button>
                            <button className="hover:bg-green-500 text-green-500 hover:text-white font-bold py-1 px-2 rounded text-xl">
                                <i className="bi bi-hand-thumbs-up-fill"></i>
                            </button>
                            <button className="hover:bg-red-500 text-red-500 hover:text-white font-bold py-1 px-2 rounded text-xl">
                                <i className="bi bi-hand-thumbs-down-fill"></i>
                            </button>
                        </div>
                        <div className="text-right">
                            {aiProvider && (
                                <span className="inline-block bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                    {aiProvider}
                  </span>
                            )}
                            {model && (
                                <span className="inline-block bg-red-600 text-white text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                    {model}
                  </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div className="flex items-center justify-end mb-4">
            <div className="bg-blue-100 p-3 rounded max-w-6xl overflow-auto">
                <Markdown
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                        code({ node, inline, className, children, ...props }: any) {
                            const match = /language-(\w+)/.exec(className || '');

                            return !inline && match ? (
                                <SyntaxHighlighter style={dracula} PreTag="div" language={match[1]} {...props}>
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            ) : (
                                <code className={className} {...props}>
                                    {children}
                                </code>
                            );
                        },
                    }}
                >
                    {content}
                </Markdown>
            </div>
            <span className="h-10 w-10 rounded-full bg-gray-200 flex items-center justify-center ml-3">
          👤
        </span>
        </div>
    );
};

export default Message;
