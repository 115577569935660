import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './pages/Dashboard';
import CreateProject from './pages/CreateProject';
import CreateSession from './pages/CreateSession';
import Login from './pages/Login';
import UpdatedPersona from "./pages/UpdatedPersona";
import { setToken } from './store/slices/authSlice';
import { RootState } from './store/index';

function App() {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.auth.token);
    const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const validateToken = async (storedToken: string) => {
        try {
            // Simula uma requisição para verificar se o token é válido
            const response = await fetch('/api/validate-token', {
                headers: { 'Authorization': `Bearer ${storedToken}` }
            });

            setIsTokenValid(response.ok);
        } catch (error) {
            console.error('Erro ao validar o token', error);
            setIsTokenValid(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            dispatch(setToken(storedToken));
            validateToken(storedToken);
        } else {
            setLoading(false);
        }
    }, [dispatch]);

    if (loading) {
        // Enquanto o token está sendo validado, você pode exibir um loader ou algo similar
        return <div>Carregando...</div>;
    }

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={isTokenValid ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
                    <Route path="/dashboard" element={isTokenValid ? <Dashboard /> : <Navigate to="/login" />} />
                    <Route path="/create-project" element={isTokenValid ? <CreateProject /> : <Navigate to="/login" />} />
                    <Route path="/create-session" element={isTokenValid ? <CreateSession /> : <Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/updated-persona" element={isTokenValid ? <UpdatedPersona /> : <Navigate to="/login" />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;