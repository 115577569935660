import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setToken, setUser } from '../store/slices/authSlice';
import { RootState } from '../store';

const { REACT_APP_API_ENDPOINT } = process.env;

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [clientId, setClientId] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const token = useSelector((state: RootState) => state.auth.token);
  const user = useSelector((state: RootState) => state.auth.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/dashboard');
    }
  }, [shouldRedirect, navigate]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      let url = `${REACT_APP_API_ENDPOINT}/user/login`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, clientId }),
      });

      if (response.ok) {
        const data = await response.json();
        dispatch(setToken(data.token));
        localStorage.setItem('token', data.token);

        url = `${REACT_APP_API_ENDPOINT}/user/me`
        const userResponse = await fetch(url, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${data.token}`,
          },
        });

        if (userResponse.ok) {
          const userData = await userResponse.json();
          dispatch(setUser(userData));
          setShouldRedirect(true);
        } else {
          console.error('Failed to fetch user data');
        }
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
      <div className="flex justify-center items-center h-screen">
        <form className="bg-white p-6 rounded shadow-md w-full max-w-sm" onSubmit={handleSubmit}>
          <h2 className="text-2xl font-bold mb-4">Login</h2>
          <div className="mb-4">
            <label className="block mb-1" htmlFor="email">Email</label>
            <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded"
                required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1" htmlFor="password">Password</label>
            <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border rounded"
                required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1" htmlFor="clientId">Client ID</label>
            <input
                type="text"
                id="clientId"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                className="w-full px-3 py-2 border rounded"
                required
            />
          </div>
          <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
          >
            Login
          </button>
        </form>
      </div>
  );
};

export default Login;