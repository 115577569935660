// src/components/Messages.tsx

import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Message from "./Message";

const Messages: React.FC = () => {
  const messages = useSelector((state: RootState) => state.ai.messages);

  console.log(messages);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(scrollToBottom, [messages]);

  return (
      <div className="chat_messages_container">
        {messages.map((m, index) => (
            <Message
                key={m.id}
                content={m.content}
                aiMessage={m.aiMessage}
                aiProvider={m.aiProvider}
                model={m.model}
                animate={index === messages.length - 1 && m.aiMessage}
            />
        ))}
        <div ref={scrollRef} />
      </div>
  );
};

export default Messages;
