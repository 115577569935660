import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { setProjects, setProject } from '../../store/slices/projectSlice';
import { setSessions, setSession } from '../../store/slices/sessionSlice';
import {clearMessages, setMessages} from '../../store/slices/aiSlice';
import { getToken } from '../../service/authService';

const { REACT_APP_API_ENDPOINT } = process.env;

interface Session {
  id: number;
  title: string;
}

const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const projects = useSelector((state: RootState) => state.project.projects);
  const currentProject = useSelector((state: RootState) => state.project.currentProject);
  const sessions = useSelector((state: RootState) => state.session.sessions);
  const currentSession = useSelector((state: RootState) => state.session.currentSession);

  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
  const [selectedSessionId, setSelectedSessionId] = useState<number | null>(null);

  useEffect(() => {
    const fetchProjects = async () => {
      const token = getToken();
      const url = `${REACT_APP_API_ENDPOINT}/chatbot/projects`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error('Failed to fetch projects');
        return;
      }

      const data = await response.json();
      dispatch(setProjects(data));
    };

    fetchProjects();
  }, [dispatch]);

  useEffect(() => {
    if (currentProject) {
      setSelectedProjectId(currentProject.id);
      fetchSessions(currentProject.id);
    }
  }, [currentProject]);

  const fetchSessions = async (projectId: number) => {
    const token = getToken();
    const url = `${REACT_APP_API_ENDPOINT}/chatbot/projects/${projectId}/sessions`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      console.error('Failed to fetch sessions');
      return;
    }

    const data = await response.json();

    if(data && data.length > 0) {

      dispatch(setSessions(data));

      if (data.length > 0) {
        const firstSession = data[0];
        handleSessionChange(firstSession);
      }
    }
  };

  const fetchMessages = async (sessionId: number, token: string) => {
    const url = `${REACT_APP_API_ENDPOINT}/chatbot/sessions/selected/${sessionId}/messages`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch messages');
    }

    const data = await response.json();
    return data;
  };

  const handleProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const projectId = parseInt(event.target.value, 10);
    const project = projects.find(p => p.id === projectId) || null;
    if (project) {
      dispatch(setProject(project));
      fetchSessions(project.id);
    }
  };

  const handleSessionChange = async (session: Session) => {
    dispatch(clearMessages());
    dispatch(setSession(session));

    const token = getToken();
    try {
      const messages = await fetchMessages(session.id, token);
      dispatch(setMessages(messages));
    } catch (error) {
      console.error('Failed to fetch messages:', error);
    }
  };

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    const menu = event.currentTarget.nextElementSibling as HTMLElement;
    if (menu) {
      menu.classList.toggle('hidden');
    }
  };

  const handleVersion = async () => {
    const response = await fetch(`${REACT_APP_API_ENDPOINT}/version`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();
    console.log(data);
  };

  const handleCreateItem = () => {
    navigate('/create-session');
  };

  const handleCopy = (session: Session, event: React.MouseEvent) => {
    event.stopPropagation();
    console.log(`Copy session: ${session.title}`);
  };

  const handleMove = (session: Session, event: React.MouseEvent) => {
    event.stopPropagation();
    console.log(`Move session: ${session.title}`);
  };

  const handleDelete = (session: Session, event: React.MouseEvent) => {
    event.stopPropagation();
    console.log(`Delete session: ${session.title}`);
  };

  const handleDownload = (session: Session, event: React.MouseEvent) => {
    event.stopPropagation();
    console.log(`Download session: ${session.title}`);
  };

  return (
      <div className="w-64 h-screen bg-gray-800 text-white flex flex-col">
        <div className="p-5">
          <h3 className="flex items-center font-bold text-lg mb-2">
            <i className="bi bi-chat-left-text-fill mr-3"></i> Material
          </h3>
        </div>
        <div className="p-5">
          <Link to="/create-project">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              <i className="bi bi-plus mr-2"></i>Criar um Projeto
            </button>
          </Link>

          <div className="py-4">
            <Link to="/updated-persona">
              <button className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded">
                <i className="bi bi-plus mr-2"></i>Persona
              </button>
            </Link>
          </div>
        </div>
        <div className="p-5">
          <label htmlFor="projects" className="block mb-2 text-sm font-medium text-white">Projetos</label>
          <select
              id="projects"
              className="bg-gray-700 text-white border border-gray-600 p-2 rounded w-full"
              value={selectedProjectId || ''}
              onChange={handleProjectChange}>
            <option value="" disabled>Selecione um projeto</option>
            {projects ? projects.map((project) => (
                <option key={project.id} value={project.id}>{project.title}</option>
            )): null}
          </select>
        </div>
        <div className="p-5">
          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" onClick={handleCreateItem}>
            <i className="bi bi-plus mr-2"></i>Criar um Item
          </button>
        </div>
        <ul id="sessions" className="flex-1 overflow-y-auto">
        {sessions && sessions.length > 0 ? (
          sessions.map((session: Session) => (
            <li key={session.id} className="p-4 hover:bg-gray-700 cursor-pointer relative flex justify-between items-center" onClick={() => handleSessionChange(session)}>
              {session.title}
              <div className="flex space-x-2">
                <button onClick={(event) => handleCopy(session, event)}>
                  <i className="bi bi-files"></i>
                </button>
                <button onClick={(event) => handleMove(session, event)}>
                  <i className="bi bi-arrows-move"></i>
                </button>
                <button onClick={(event) => handleDelete(session, event)}>
                  <i className="bi bi-trash"></i>
                </button>
                <button onClick={(event) => handleDownload(session, event)}>
                  <i className="bi bi-download"></i>
                </button>
              </div>
            </li>
          ))
        ) : (
          <li className="p-4 text-center">No sessions available</li>
        )}
      </ul>
      </div>
  );
};

export default Sidebar;
