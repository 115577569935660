// src/pages/UpdatedPersona.tsx

import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { getToken } from '../service/authService';

const { REACT_APP_API_ENDPOINT } = process.env;

const UpdatedPersona: React.FC = () => {
    const [personaId, setPersonaId] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [searchId, setSearchId] = useState<string>('');
    const [responseJson, setResponseJson] = useState<any>(null);
    const token = getToken();

    const fetchPersona = async (id: string) => {
        const url = `${REACT_APP_API_ENDPOINT}/personas/${id}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch persona');
            }

            const data = await response.json();
            setPersonaId(data.id);
            setTitle(data.title);
            setContent(data.content);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSave = async () => {
        const url = personaId
            ? `${REACT_APP_API_ENDPOINT}/personas/${personaId}`
            : `${REACT_APP_API_ENDPOINT}/personas`;
        const method = personaId ? 'PUT' : 'POST';

        const payload = {
            title,
            content,
        };

        try {
            const response = await fetch(url, {
                method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`Failed to ${personaId ? 'update' : 'create'} persona`);
            }

            const data = await response.json();
            setResponseJson(data);

            console.log(`Persona ${personaId ? 'Updated' : 'Created'}:`, data);

            setPersonaId('');
            setTitle('');
            setContent('');
            setSearchId('');

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSearch = async () => {
        if (searchId) {
            fetchPersona(searchId);
        }
    };

    const handleCancel = () => {
        setPersonaId('');
        setTitle('');
        setContent('');
        setSearchId('');
        setResponseJson(null);
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">{personaId ? 'Edit Persona' : 'Create Persona'}</h1>
            <div className="mb-4">
                <input
                    type="text"
                    className="border p-2 w-full mb-2"
                    placeholder="Search Persona by ID"
                    value={searchId}
                    onChange={(e) => setSearchId(e.target.value)}
                />
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSearch}
                >
                    Search
                </button>
            </div>
            <input
                type="text"
                className="border p-2 mb-4 w-full"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
            />
            <textarea
                className="border p-2 mb-4 w-full"
                rows={10}
                placeholder="Content"
                value={content}
                onChange={(e) => setContent(e.target.value)}
            />
            <div className="flex space-x-4">
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSave}
                >
                    {personaId ? 'Update Persona' : 'Create Persona'}
                </button>
                <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleCancel}
                >
                    Cancel
                </button>
                <Link to="/dashboard">
                    <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                        <i className="bi bi-speedometer2 mr-2"></i>Dashboard
                    </button>
                </Link>
            </div>
            {responseJson && (
                <div className="mt-4 p-4 border border-gray-300 rounded bg-gray-100">
                    <h2 className="text-xl font-bold mb-2">API Response:</h2>
                    <pre className="text-sm">{JSON.stringify(responseJson, null, 2)}</pre>
                </div>
            )}
        </div>
    );
};

export default UpdatedPersona;
